var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "hamburgerBar"
  }, [_c('hamburger', {
    staticClass: "hamburger-container",
    attrs: {
      "id": "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      "toggleClick": _vm.toggleSideBar
    }
  }), !_vm.topNav ? _c('breadcrumb', {
    staticClass: "breadcrumb-container",
    attrs: {
      "id": "breadcrumb-container"
    }
  }) : _vm._e(), _vm.topNav ? _c('top-nav', {
    staticClass: "topmenu-container",
    attrs: {
      "id": "topmenu-container"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };