import { DeviceManageApi } from "@/api/iot/DeviceManageApi";
import DeviceLive from "@/components/DeviceLive/index";
import { PushMsgApi } from "@/api/system/PushMsgApi";
import Cookies from "js-cookie";
export default {
  name: "WsMsg",
  components: {
    DeviceLive
  },
  data() {
    return {
      infoDialog: {
        title: "提示",
        width: "400px",
        isShow: false,
        isSingle: true,
        form: {
          title: '',
          body: ''
        }
      },
      deviceDialog: {
        title: "提示",
        width: "600px",
        isShow: false,
        isSingle: true,
        form: {
          deviceId: null,
          deviceLive: {
            accessToken: null,
            url: null
          },
          deviceInfo: {
            // "address": "e福州便民服务终端董屿地铁",
            // "code": "ae5d9b5a22befc755f543d64fbca500b",
            // "createTime": "2021-06-23 17:03:37",
            // "id": "1407625375782416385",
            // "imei": "00002438",
            // "lastTime": "2021-08-07 23:11:41",
            // "lat": 26.041839,
            // "lng": 119.207122,
            // "location": "大堂",
            // "manager": "李厚厚",
            // "name": "智能用电测试设备1",
            // "productId": 1,
            // "projectId": "1415958573020192770",
            // "remark": null,
            // "state": "ON",
            // "version": "6"
          }
        }
      },
      webcamDialog: {
        title: "告警",
        width: "1200px",
        isShow: false,
        isSingle: true,
        form: {
          deviceId: null,
          webcamId: null,
          webcamLive: {
            accessToken: null,
            url: null
          },
          webcamInfo: {
            // "address": "e福州便民服务终端董屿地铁",
            // "code": "ae5d9b5a22befc755f543d64fbca500b",
            // "createTime": "2021-06-23 17:03:37",
            // "id": "1407625375782416385",
            // "imei": "00002438",
            // "lastTime": "2021-08-07 23:11:41",
            // "lat": 26.041839,
            // "lng": 119.207122,
            // "location": "大堂",
            // "manager": "李厚厚",
            // "name": "智能用电测试设备1",
            // "productId": 1,
            // "projectId": "1415958573020192770",
            // "remark": null,
            // "state": "ON",
            // "version": "6"
          },
          deviceLive: {
            accessToken: null,
            url: null
          },
          deviceInfo: {
            // "address": "e福州便民服务终端董屿地铁",
            // "code": "ae5d9b5a22befc755f543d64fbca500b",
            // "createTime": "2021-06-23 17:03:37",
            // "id": "1407625375782416385",
            // "imei": "00002438",
            // "lastTime": "2021-08-07 23:11:41",
            // "lat": 26.041839,
            // "lng": 119.207122,
            // "location": "大堂",
            // "manager": "李厚厚",
            // "name": "智能用电测试设备1",
            // "productId": 1,
            // "projectId": "1415958573020192770",
            // "remark": null,
            // "state": "ON",
            // "version": "6"
          }
        }
      },
      oprList: {
        start: false,
        loading: false,
        noMore: false,
        type: 'DEVICE_OPR',
        count: 0,
        page: {
          index: 1,
          size: 10
        },
        rows: []
      },
      alarmList: {
        start: false,
        loading: false,
        noMore: false,
        type: 'DEVICE_ALARM',
        count: 0,
        page: {
          index: 1,
          size: 10
        },
        rows: []
      },
      sysList: {
        start: false,
        loading: false,
        noMore: false,
        type: 'SYS',
        count: 0,
        page: {
          index: 1,
          size: 10
        },
        rows: []
      },
      allCount: 0,
      activeName: "DEVICE_ALARM",
      visible: false,
      websock: null,
      timeTask: null
    };
  },
  computed: {
    oprDisabled() {
      return this.oprList.loading || this.oprList.noMore;
    },
    alarmDisabled() {
      return this.alarmList.loading || this.alarmList.noMore;
    },
    sysDisabled() {
      return this.sysList.loading || this.sysList.noMore;
    }
  },
  created() {
    this.initWebSocket();
    this.getMsgCount();
    // window.addEventListener('unhandledrejection', event => {
    //     alert("请允许浏览器的声音播放")
    // });
  },

  destroyed: function () {
    //页面销毁时关闭长连接
    if (this.websock) {
      this.websock.close();
    }
  },
  methods: {
    getInfo(dialog) {
      if (dialog === this.deviceDialog) {
        DeviceManageApi.info(this.deviceDialog.form.deviceId).then(data => {
          this.deviceDialog.form.deviceInfo = data;
        });
      }
      if (dialog === this.webcamDialog) {
        DeviceManageApi.info(this.webcamDialog.form.deviceId).then(data => {
          this.webcamDialog.form.deviceInfo = data;
        });
        DeviceManageApi.info(this.webcamDialog.form.webcamId).then(data => {
          this.webcamDialog.form.webcamInfo = data;
        });
      }
    },
    getLive(dialog) {
      if (dialog === this.deviceDialog) {
        DeviceManageApi.infoLive(this.deviceDialog.form.deviceId).then(data => {
          this.deviceDialog.form.deviceLive = data;
        });
      }
      if (dialog === this.webcamDialog) {
        DeviceManageApi.infoLive(this.webcamDialog.form.deviceId).then(data => {
          this.webcamDialog.form.deviceLive = data;
        });
        DeviceManageApi.infoLive(this.webcamDialog.form.webcamId).then(data => {
          this.webcamDialog.form.webcamLive = data;
        });
      }
    },
    readAll() {
      if (this.allCount === 0) {
        this.$message({
          message: '消息全部已读',
          type: 'success'
        });
        return;
      }
      PushMsgApi.readAll().then(data => {
        this.$message({
          message: '消息全部已读',
          type: 'success'
        });
        this.loadMsg();
      });
    },
    readMsg(item) {
      PushMsgApi.read(item.id).then(data => {
        this.$message({
          message: '消息已读',
          type: 'success'
        });
        if (item.payload.webCamId && item.payload.deviceId) {
          //摄像头监控详情
          this.webcamDialog.form.deviceId = item.payload.deviceId;
          this.webcamDialog.form.webcamId = item.payload.webCamId;
          this.getInfo(this.webcamDialog);
          this.getLive(this.webcamDialog);
          this.webcamDialog.isShow = true;
        } else if (item.payload.deviceId) {
          //普通详情
          this.deviceDialog.form.deviceId = item.payload.deviceId;
          this.getInfo(this.deviceDialog);
          this.getLive(this.deviceDialog);
          this.deviceDialog.isShow = true;
        } else {
          this.infoDialog.form = item;
          this.infoDialog.isShow = true;
        }
        this.loadMsg();
      });
    },
    getMsgCount() {
      PushMsgApi.unReadCount().then(data => {
        this.allCount = 0;
        data.forEach(item => {
          this.allCount += item.count;
          if (item.infoType === 'DEVICE_OPR') {
            this.oprList.count = item.count;
          }
          if (item.infoType === 'DEVICE_ALARM') {
            this.alarmList.count = item.count;
          }
          if (item.infoType === 'SYS') {
            this.sysList.count = item.count;
          }
        });
      });
    },
    showMsg() {
      this.visible = !this.visible;
      this.loadMsg();
    },
    loadMsg() {
      this.getMsgCount();
      this.oprList = {
        start: false,
        loading: false,
        noMore: false,
        type: 'DEVICE_OPR',
        count: 0,
        page: {
          index: 1,
          size: 10
        },
        rows: []
      };
      this.getOprList();
      this.alarmList = {
        start: false,
        loading: false,
        noMore: false,
        type: 'DEVICE_ALARM',
        count: 0,
        page: {
          index: 1,
          size: 10
        },
        rows: []
      };
      this.getAlarmList();
      this.sysList = {
        start: false,
        loading: false,
        noMore: false,
        type: 'SYS',
        count: 0,
        page: {
          index: 1,
          size: 10
        },
        rows: []
      };
      this.getSysList();
    },
    handleClick() {
      if (this.activeName === 'DEVICE_OPR' && !this.oprList.start) {
        this.getOprList();
      }
      if (this.activeName === 'DEVICE_ALARM' && !this.alarmList.start) {
        this.getAlarmList();
      }
      if (this.activeName === 'SYS' && !this.sysList.start) {
        this.getSysList();
      }
    },
    getOprList() {
      this.getMsgList(this.oprList);
      this.oprList.page.index++;
    },
    getAlarmList() {
      this.getMsgList(this.alarmList);
      this.alarmList.page.index++;
    },
    getSysList() {
      this.getMsgList(this.sysList);
      this.sysList.page.index++;
    },
    getMsgList(tmpList) {
      tmpList.loading = true;
      PushMsgApi.unReadList(tmpList.page.index, tmpList.page.size, {
        infoType: tmpList.type
      }).then(data => {
        tmpList.start = true;
        tmpList.loading = false;
        tmpList.rows = tmpList.rows.concat(data.rows);
        if (data.rows.length < tmpList.page.size) {
          tmpList.noMore = true;
        }
      }).catch(error => {
        tmpList.loading = false;
      });
    },
    initWebSocket() {
      let protocol = "ws://";
      if (window.location.protocol === 'https:') {
        protocol = "wss://";
      }
      this.websock = new WebSocket(protocol + window.location.host + "/ws");
      this.websock.onopen = this.onOpen;
      this.websock.onerror = this.onError;
      this.websock.onmessage = this.onMessage;
      this.websock.onclose = this.onClose;
      let pingMsg = "ping";
      this.timeTask = setInterval(() => {
        this.websock.send(pingMsg);
      }, 60 * 1000);
      console.log("websocket 请求，window.location.protocol：" + window.location.protocol + ",window.location.host:" + window.location.host);
    },
    onError() {
      console.log("websocket 错误，window.location.protocol：" + window.location.protocol + ",window.location.host:" + window.location.host);
    },
    onOpen() {
      console.log("websocket 连接");
    },
    onClose() {
      clearInterval(this.timeTask);
      console.log("websocket 关闭");
    },
    onMessage(event) {
      let msg = JSON.parse(event.data);
      this.$notify({
        title: msg.title,
        message: msg.body,
        duration: 10 * 1000
      });
      console.log(msg);
      this.loadMsg();
      //播放声音
      let notifyAudio = Cookies.get("notifyAudio");
      if ('OFF' !== notifyAudio) {
        let ttsAudio = document.getElementById('notifyAudio');
        ttsAudio.play();
      }

      //如果是关联摄像头的信息时，要进行弹窗显示
      if (msg.payload && msg.payload.msgType) {
        if (msg.payload.msgType == 'WEBCAM') {
          this.readMsg(msg);
        }
      }
    },
    formatDate(value) {
      if (value && value.length === 8) {
        return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
      }
      return value;
    },
    formatTime(value) {
      if (value && value.length === 6) {
        return value.substring(0, 2) + ":" + value.substring(2, 4) + ":" + value.substring(4, 6);
      }
      return value;
    }
  }
};