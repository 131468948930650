export default {
  name: "CommonTable",
  props: {
    page: {
      index: 1,
      size: 10,
      total: 0
    },
    showPage: {
      type: Boolean,
      default: false
    },
    smallPage: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    pageSizeChange(val) {
      this.page.index = 1;
      this.page.size = val;
      this.$emit("pageChange", this.page);
    },
    pageIndexChange(val) {
      this.page.index = val;
      this.$emit("pageChange", this.page);
    }
  }
};