var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('el-menu', {
    attrs: {
      "default-active": _vm.activeMenu,
      "mode": "horizontal"
    },
    on: {
      "select": _vm.handleSelect
    }
  }, [_vm._l(_vm.topMenus, function (item, index) {
    return [index < _vm.visibleNumber ? _c('el-menu-item', {
      key: index,
      style: {
        '--theme': _vm.theme
      },
      attrs: {
        "index": item.path
      }
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": item.meta.icon
      }
    }), _vm._v(" " + _vm._s(item.meta.title) + " ")], 1) : _vm._e()];
  }), _vm.topMenus.length > _vm.visibleNumber ? _c('el-submenu', {
    attrs: {
      "index": "more"
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v("更多菜单")]), _vm._l(_vm.topMenus, function (item, index) {
    return [index >= _vm.visibleNumber ? _c('el-menu-item', {
      key: index,
      attrs: {
        "index": item.path
      }
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": item.meta.icon
      }
    }), _vm._v(" " + _vm._s(item.meta.title) + " ")], 1) : _vm._e()];
  })], 2) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };