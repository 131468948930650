import CommonApi from '@/utils/CommonApi';
export class LoginApi {
  static login(data) {
    return CommonApi.postJson('/index/login', data);
  }
  static getCodeImg() {
    return CommonApi.get("/index/img");
  }
  static getInfo() {
    return CommonApi.get("/index/info");
  }
  static logout() {
    return CommonApi.get('/index/logout');
  }
  static getMenuTree() {
    return CommonApi.get('/index/menu');
  }
}