export const PERMISSION = {
  "setting-user": {
    name: "用户管理",
    component: "system/user/index",
    path: "/system/user"
  },
  "setting-role": {
    name: "角色管理",
    component: "system/role/index",
    path: "/system/role"
  },
  "setting-menu": {
    name: "菜单管理",
    component: "system/menu/index",
    path: "/system/menu"
  },
  "setting-log": {
    name: "日志管理",
    component: "system/log/index",
    path: "/system/log"
  },
  "setting-config": {
    name: "全局配置",
    component: "system/config/index",
    path: "/system/config"
  },
  "setting-area": {
    name: "区域管理",
    component: "system/area/index",
    path: "/system/area"
  },
  "setting-mail": {
    name: "邮箱配置",
    component: "system/config/MailSysConfig",
    path: "/system/mailconfig"
  },
  "setting-storage": {
    name: "存储配置",
    component: "system/oss/index",
    path: "/system/oss"
  },
  "setting-sms": {
    name: "短信配置",
    component: "system/sms/index",
    path: "/system/sms"
  },
  "setting-tel": {
    name: "语音电话",
    component: "system/tel/index",
    path: "system/tel"
  },
  "setting-dicttype": {
    name: "字典配置",
    component: "system/dict/index",
    path: "/system/dict"
  },
  "setting-dictdata": {
    name: "字典数据",
    hidden: true,
    // 隐藏
    component: "system/dict/SysDictData",
    path: "/system/dictdata"
  },
  "iot-category": {
    name: "系统分类",
    component: "iot/category/index",
    path: "/iot/category"
  },
  "iot-product": {
    name: "产品管理",
    component: "iot/product/index",
    path: "/iot/product"
  },
  "iot-device": {
    name: "设备管理",
    component: "iot/device/index",
    path: "/iot/device"
  },
  "rule-manage": {
    name: "规则实例",
    component: "rule/manage/index",
    path: "/rule/manage"
  },
  "rule-notify": {
    name: "智能联动",
    component: "rule/notify/index",
    path: "/rule/notify"
  },
  "rule-event": {
    name: "事件记录",
    component: "rule/event/index",
    path: "/rule/event"
  },
  "rule-alarm": {
    name: "告警记录",
    component: "rule/alarm/index",
    path: "/rule/alarm"
  },
  "project-archives": {
    name: "档案管理",
    component: "project/archives/index",
    path: "/project/archives"
  },
  "project-img": {
    name: "图片分类",
    component: "project/img/index",
    path: "/project/img"
  },
  "project-imgdata": {
    name: "图片管理",
    component: "project/img/imgData",
    path: "/project/imgdata"
  },
  "project-doc": {
    name: "文章管理",
    component: "project/doc/index",
    path: "/project/doc"
  },
  "project-manage": {
    name: "项目管理",
    component: "project/manage/index",
    path: "/project/manage"
  },
  "project-user": {
    name: "项目用户",
    component: "project/user/index",
    path: "/project/user"
  },
  "project-order": {
    name: "工单管理",
    component: "project/order/index",
    path: "/project/order"
  },
  "user-order": {
    name: "我的工单",
    component: "project/myorder/index",
    path: "/project/myorder"
  },
  "history-order": {
    name: "历史工单",
    component: "project/hisorder/index",
    path: "/project/hisorder"
  },
  "provider-manage": {
    name: "服务商管理",
    component: "provider/manage/index",
    path: "/provider/manage"
  },
  "project-type": {
    name: "项目类型",
    component: "project/type/index",
    path: "/project/type"
  },
  "project-industry": {
    name: "行业管理",
    component: "project/industry/index",
    path: "/project/industry"
  },
  "provider-user": {
    name: "用户管理",
    component: "provider/user/index",
    path: "/provider/user"
  },
  "report-alarm": {
    name: "告警统计",
    component: "report/alarm/index",
    path: "/report/alarm"
  },
  "report-device": {
    name: "设备统计",
    component: "report/device/index",
    path: "/report/device"
  },
  "report-email": {
    name: "发送配置",
    component: "report/email/index",
    path: "/report/email"
  },
  "report-order": {
    name: "工单统计",
    component: "report/order/index",
    path: "/report/order"
  },
  "inspection-tasktimer": {
    name: "巡检任务",
    component: "inspection/tasktimer/index",
    path: "/inspection/tasktimer"
  },
  "inspection-order": {
    name: "巡检任务单",
    component: "inspection/order/index",
    path: "/inspection/order"
  },
  "box-station": {
    name: "消防站点",
    component: "box/station/index",
    path: "/box/station"
  },
  "box-category": {
    name: "物资分类",
    component: "box/category/index",
    path: "/box/category"
  },
  "box-ware": {
    name: "物品管理",
    component: "box/ware/index",
    path: "/box/ware"
  },
  "box-warein": {
    name: "入库记录",
    component: "box/warein/index",
    path: "/box/warein"
  },
  "box-wareout": {
    name: "出库记录",
    component: "box/wareout/index",
    path: "/box/wareout"
  },
  "box-warelend": {
    name: "借出记录",
    component: "box/warelend/index",
    path: "/box/warelend"
  },
  "box-warerefund": {
    name: "归还记录",
    component: "box/warerefund/index",
    path: "/box/warerefund"
  },
  "box-device": {
    name: "消防主机",
    component: "box/device/index",
    path: "/box/device"
  },
  "box-cell": {
    name: "格子管理",
    component: "box/cell/index",
    path: "/box/cell"
  },
  "box-emergency": {
    name: "应急管理",
    component: "box/emergency/index",
    path: "/box/emergency"
  },
  "box-risk": {
    name: "隐患信息",
    component: "box/risk/index",
    path: "/box/risk"
  },
  "box-startup": {
    name: "启动画面",
    component: "box/startup/index",
    path: "/box/startup"
  },
  "box-attnd": {
    name: "考勤记录",
    component: "box/attnd/index",
    path: "/box/attnd"
  },
  "about-us": {
    name: "关于我们",
    component: "system/aboutus/index",
    path: "/system/aboutus"
  },
  "sys-msg": {
    name: "系统消息",
    component: "system/sysmsg/index",
    path: "/system/sysmsg"
  },
  "device-qrcode": {
    name: "设备库存",
    component: "iot/qrcode/index",
    path: "/iot/qrcode"
  },
  "push-msg": {
    name: "通知记录",
    component: "system/pushmsg/index",
    path: "/system/pushmsg"
  }
};