import CommonApi from '@/utils/CommonApi';
export class PushMsgApi {
  static readAll() {
    return CommonApi.get('/index/push/msg/read/all');
  }
  static read(id) {
    return CommonApi.get(`/index/push/msg/read?id=${id}`);
  }
  static unReadCount() {
    return CommonApi.get('/index/push/msg/unread/count');
  }
  static unReadList(pageIndex, pageSize, query) {
    return CommonApi.postJson('/index/push/msg/unread/list', {
      "index": pageIndex,
      "size": pageSize,
      "query": query
    });
  }
  static page(pageIndex, pageSize, query) {
    return CommonApi.postJson('/index/push/msg/page', {
      "index": pageIndex,
      "size": pageSize,
      "query": query
    });
  }
}