export default {
  name: "CommonDialog",
  props: {
    dialog: {
      title: {
        type: String,
        default: "1"
      },
      isShow: {
        type: Boolean,
        default: false
      },
      isAdd: {
        type: Boolean,
        default: false
      },
      isSingle: {
        type: Boolean,
        default: false
      },
      addContinue: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: "200px"
      }
    }
  },
  data() {
    return {
      clickClose: false,
      pressClose: false,
      modal: true,
      closeShow: false,
      appendToBody: true
    };
  },
  watch: {
    isShow(val) {
      this.$emit('update:isShow', val);
    },
    addContinue(val) {
      this.$emit('update:addContinue', val);
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.dialog.isShow = false;
    },
    confirm() {
      this.$emit('confirm');
    }
  }
};