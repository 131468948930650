import { mapGetters } from 'vuex';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
import WsMsg from '@/components/WsMsg';
import EnableAudio from '@/components/WsMsg/EnableAudio';
import { WeatherApi } from '@/api/utils/WeatherApi';
import weatherImg from '@/assets/icons/svg/weather.svg';
export default {
  components: {
    Screenfull,
    SizeSelect,
    WsMsg,
    EnableAudio
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device']),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        });
      }
    }
  },
  mounted() {
    this.getWeather();
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    /** 查询区域下拉树结构 */
    async getWeather() {
      WeatherApi.query().then(data => {
        this.weather = data;
      });
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/index';
        });
      }).catch(() => {});
    }
  },
  data() {
    return {
      weather: '',
      weatherImg: weatherImg
    };
  }
};