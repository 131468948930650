import request from '@/utils/request';
export default class CommonApi {
  static postJson(url, data) {
    return request({
      url: url,
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      data: data
    });
  }
  static get(url) {
    return request({
      url: url,
      method: 'get'
    });
  }
}