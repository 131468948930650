import CommonApi from '@/utils/CommonApi';
export class DeviceManageApi {
  static infoOpr(pageIndex, pageSize, id) {
    return CommonApi.postJson("/manage/device/info/opr", {
      "index": pageIndex,
      "size": pageSize,
      "query": {
        deviceId: id
      }
    });
  }
  static infoCommand(pageIndex, pageSize, id) {
    return CommonApi.postJson("/manage/device/info/command", {
      "index": pageIndex,
      "size": pageSize,
      "query": {
        deviceId: id
      }
    });
  }
  static infoEvent(pageIndex, pageSize, id) {
    return CommonApi.postJson("/manage/device/info/event", {
      "index": pageIndex,
      "size": pageSize,
      "query": {
        deviceId: id
      }
    });
  }
  static infoAlarm(pageIndex, pageSize, id) {
    return CommonApi.postJson("/manage/device/info/alarm", {
      "index": pageIndex,
      "size": pageSize,
      "query": {
        deviceId: id
      }
    });
  }
  static infoLive(id) {
    return CommonApi.get(`/manage/device/info/live/${id}`);
  }
  static infoData(id) {
    return CommonApi.get(`/manage/device/info/data/${id}`);
  }
  static info(id) {
    return CommonApi.get(`/manage/device/info?id=${id}`);
  }
  static datalist(pageIndex, pageSize, query) {
    return CommonApi.postJson('/manage/device/data', {
      "index": pageIndex,
      "size": pageSize,
      "query": query
    });
  }
  static listProject(areaId) {
    if (!areaId) {
      areaId = 1;
    }
    return CommonApi.get(`/manage/device/list/project?areaId=${areaId}`);
  }
  static pageProjects(pageIndex, pageSize, areaId) {
    if (!areaId) {
      areaId = 1;
    }
    return CommonApi.postJson('/manage/device/page/project', {
      "index": pageIndex,
      "size": pageSize,
      "query": areaId
    });
  }
  static listCategory(projectId) {
    return CommonApi.get(`/manage/device/list/category?projectId=${projectId}`);
  }
  static listProduct(projectId, categoryId) {
    return CommonApi.get(`/manage/device/list/product?projectId=${projectId}&categoryId=${categoryId}`);
  }
  static list(pageIndex, pageSize, query) {
    return CommonApi.postJson('/manage/device/list', {
      "index": pageIndex,
      "size": pageSize,
      "query": query
    });
  }
  static selectByProjectAndCategory(projectId, categoryId) {
    return CommonApi.get(`/manage/device/selectByProjectAndCategory?projectId=${projectId}&categoryId=${categoryId}`);
  }
  static map(pageIndex, pageSize, query) {
    return CommonApi.postJson(`/manage/device/map`, {
      "index": pageIndex,
      "size": pageSize,
      "query": query
    });
  }
  static add(params) {
    return CommonApi.postJson("/manage/device/add", params);
  }
  static del(id) {
    return CommonApi.get(`/manage/device/del?id=${id}`);
  }
  static enable(id, state) {
    return CommonApi.get(`/manage/device/enable?id=${id}&state=${state}`);
  }
  static deploy(id, state) {
    return CommonApi.get(`/manage/device/deploy?id=${id}&state=${state}`);
  }
  static update(params) {
    return CommonApi.postJson("/manage/device/update", params);
  }
}