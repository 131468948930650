import Cookies from "js-cookie";
export default {
  name: "EnableAudio",
  data() {
    return {
      enable: false
    };
  },
  methods: {
    switchEnable(state) {
      this.enable = state;
      if (state) {
        Cookies.set("notifyAudio", 'ON', {
          expires: 30
        });
      } else {
        Cookies.set("notifyAudio", 'OFF', {
          expires: 30
        });
      }
    }
  },
  mounted() {
    let notifyAudio = Cookies.get("notifyAudio");
    if ('OFF' !== notifyAudio) {
      this.enable = true;
      Cookies.set("notifyAudio", 'ON', {
        expires: 30
      });
    }
  }
};