import Vue from 'vue';
import Cookies from 'js-cookie';
import Element from 'element-ui';
import './assets/styles/element-variables.scss';
import '@/assets/styles/index.scss'; // global css
import '@/assets/styles/framework.scss'; // systerm css
import '@/assets/styles/iconfont/iconfont.css';
import App from './App.vue';
import store from './store';
import router from './router';
import permission from './directive/permission';
import './assets/icons'; // icon
import './permission';
import CommonDialog from "@/components/CommonDialog";
import CommonTable from "@/components/CommonTable";
import EmptyDiv from "@/components/EmptyDiv";
import JsonViewer from 'vue-json-viewer';
import VueJsonEditor from 'vue-json-editor';
import AmapVue from '@amap/amap-vue';
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view';
import prototype from './utils/prototype';
import Moment from 'moment';
AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = 'a0efb86b7a6749473774321662171a95';
AmapVue.config.plugins = ['AMap.ToolBar', 'AMap.MoveAnimation'
// 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

Vue.use(AmapVue);
Vue.use(dataV);
Vue.use(permission);
Vue.use(prototype);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
});

// 定义全局时间戳过滤器
Vue.filter('formatDate', function (value) {
  if (value && value.length === 6) {
    return value.substring(0, 2) + ":" + value.substring(2, 4) + ":" + value.substring(4, 6);
  }
  if (value) {
    return Moment(value).format('YYYY-MM-DD');
  }
});

// 定义全局时间戳过滤器
Vue.filter('formatTime', function (value) {
  if (value && value.length === 6) {
    return value.substring(0, 2) + ":" + value.substring(2, 4) + ":" + value.substring(4, 6);
  }
  if (value) {
    let time = new Date(parseInt(value));
    let hh = (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':';
    let mm = (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':';
    let ss = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
    return hh + mm + ss;
  }
});

// 全局组件挂载
Vue.component('CommonDialog', CommonDialog);
Vue.component('CommonTable', CommonTable);
Vue.component('EmptyDiv', EmptyDiv);
Vue.component('JsonViewer', JsonViewer);
Vue.component('VueJsonEditor', VueJsonEditor);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');