var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tab-table"
  }, [_c('div', {
    staticClass: "tab-table-bar"
  }, [_c('div', {
    staticClass: "tab-bar-search"
  }, [_vm._t("bar-search")], 2), _c('div', {
    staticClass: "tab-bar-toolbar"
  }, [_vm._t("bar-toolbar")], 2)]), _c('div', {
    staticClass: "tab-table-content"
  }, [_vm._t("table")], 2), !_vm.showPage ? _c('div', {
    staticClass: "tab-table-footer"
  }, [_vm.smallPage ? _c('el-pagination', {
    attrs: {
      "slot": "page",
      "small": "",
      "current-page": _vm.page.index,
      "page-sizes": [10, 30, 50, 100],
      "page-size": _vm.page.size,
      "layout": "total, prev, pager, next",
      "total": _vm.page.total
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageIndexChange
    },
    slot: "page"
  }) : _c('el-pagination', {
    attrs: {
      "slot": "page",
      "background": "",
      "current-page": _vm.page.index,
      "page-sizes": [10, 30, 50, 100],
      "page-size": _vm.page.size,
      "layout": "total, sizes, prev, pager, next, jumper",
      "total": _vm.page.total
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageIndexChange
    },
    slot: "page"
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };