import "core-js/modules/es.array.push.js";
import { constantRoutes } from '@/router';
import { LoginApi } from '@/api/LoginApi';
import Layout from '@/layout/index';
import ParentView from '@/components/ParentView';
import { PERMISSION } from "@/utils/permission";
const permission = {
  state: {
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = constantRoutes.concat(routes);
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      // 顶部导航菜单默认添加统计报表栏指向首页
      const index = [{
        path: 'index',
        meta: {
          title: '导航',
          icon: 'dashboard'
        }
      }];
      state.topbarRouters = routes.concat(index);
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes;
    }
  },
  actions: {
    // 获取用户信息
    GetInfo({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        LoginApi.getInfo().then(res => {
          if (res.roles && res.roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles);
            commit('SET_PERMISSIONS', res.permissions);
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT']);
          }
          if (res.projects && res.projects.length > 0) {
            commit('SET_PROJECTS', res.projects);
          } else {
            commit('SET_PROJECTS', []);
          }
          commit('SET_PROVIDER', res.provider);
          commit('SET_NAME', res.userName);
          commit('SET_USERTYPE', res.userType);
          var avatarImg = null;
          if (null == res.avatarImg || 0 == res.avatarImg.trim().length) {
            avatarImg = require("@/assets/images/profile.png");
          } else {
            avatarImg = res.avatarImg;
          }
          commit('SET_AVATAR', avatarImg);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 生成路由
    GenerateRoutes({
      commit
    }) {
      return new Promise(resolve => {
        LoginApi.getMenuTree().then(data => {
          initMap(data);
          const sdata = JSON.parse(JSON.stringify(data));
          const rdata = JSON.parse(JSON.stringify(data));
          const sidebarRoutes = filterAsyncRouter(sdata);
          const rewriteRoutes = filterAsyncRouter(rdata, false, true);
          rewriteRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          commit('SET_ROUTES', rewriteRoutes);
          commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes));
          commit('SET_DEFAULT_ROUTES', sidebarRoutes);
          commit('SET_TOPBAR_ROUTES', sidebarRoutes);
          resolve(rewriteRoutes);
        });
      });
    }
  }
};
function initMap(data) {
  let menuMap = new Map();
  Object.keys(PERMISSION).forEach(function (key) {
    menuMap.set(key, PERMISSION[key]);
  });
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    item.meta = {
      "title": item.name,
      "icon": item.icon,
      "noCache": false
    };
    let menu = menuMap.get(item.code);
    if (menu) {
      item.component = menu.component;
      item.path = menu.path;
    } else {
      item.path = "";
      item.component = "Layout";
    }
    if (item.children && item.children.length > 0) {
      initMap(item.children);
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout;
      } else if (route.component === 'ParentView') {
        route.component = ParentView;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route['children'];
      delete route['redirect'];
    }
    return true;
  });
}
function filterChildren(childrenMap, lastRouter = false) {
  var children = [];
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path;
    }
    children = children.concat(el);
  });
  return children;
}
export const loadView = view => {
  // 路由懒加载
  return resolve => require([`@/views/${view}`], resolve);
};
export default permission;