var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.enable ? _c('svg-icon', {
    attrs: {
      "class-name": "online-icon",
      "icon-class": "onvolume"
    },
    on: {
      "click": function ($event) {
        return _vm.switchEnable(false);
      }
    }
  }) : _c('svg-icon', {
    attrs: {
      "class-name": "switch-icon",
      "icon-class": "offvolume"
    },
    on: {
      "click": function ($event) {
        return _vm.switchEnable(true);
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };