import EZUIKit from "ezuikit-js";
export default {
  name: "DeviceLive",
  props: {
    accessToken: String,
    url: String
  },
  mounted() {
    console.log(this.accessToken);
    console.log(this.url);
    console.group("mounted 组件挂载完毕状态===============》");
    var player = new EZUIKit.EZUIKitPlayer({
      autoplay: true,
      id: "video-container",
      accessToken: this.accessToken,
      url: this.url,
      template: "simple",
      // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // 视频上方头部控件
      header: ["capturePicture", "save", "zoom"],
      // 如果templete参数不为simple,该字段将被覆盖
      //plugin: ['talk'],                       // 加载插件，talk-对讲
      // 视频下方底部控件
      footer: ["hd", "fullScreen"],
      // 如果template参数不为simple,该字段将被覆盖
      audio: 0,
      // 是否默认开启声音 0 - 关闭 1 - 开启
      width: 400,
      height: 240
    });
    console.log("player", player);
    // setTimeout(()=>{
    //   player.stop(); // 方法调用示例，10秒后关闭视频
    // },10000)
  }
};