var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "weather"
  }, [_vm.device !== 'mobile' && _vm.weather.temperature != null && _vm.weather.temperature != '' ? [_c('table', [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "12px"
    }
  }), _c('td', [_vm._v(_vm._s(_vm.weather.city))]), _vm._v(" "), _c('td', {
    staticStyle: {
      "width": "12px"
    }
  }), _vm._m(0), _vm._v(" "), _c('td', [_vm._v(_vm._s(_vm.weather.weather) + " ")]), _vm._v(" "), _c('td', {
    staticStyle: {
      "width": "12px"
    }
  }), _c('td', [_vm._v(_vm._s(_vm.weather.temperature) + " ℃ ")]), _c('td', {
    staticStyle: {
      "width": "12px"
    }
  }), _vm._m(1), _vm._v(" "), _c('td', [_vm._v("湿度 " + _vm._s(_vm.weather.humidity) + "% ")])])])])] : _vm._e()], 2), _c('div', {
    staticClass: "right-menu"
  }, [_vm.device !== 'mobile' ? [_c('screenfull', {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      "id": "screenfull"
    }
  }), _c('el-tooltip', {
    attrs: {
      "content": "布局大小",
      "effect": "dark",
      "placement": "bottom"
    }
  }, [_c('size-select', {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      "id": "size-select"
    }
  })], 1), _c('el-tooltip', {
    attrs: {
      "content": "语音播报",
      "effect": "dark",
      "placement": "bottom"
    }
  }, [_c('enable-audio', {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      "id": "push-msg-audio"
    }
  })], 1), _c('el-tooltip', {
    attrs: {
      "content": "消息",
      "effect": "dark",
      "placement": "bottom"
    }
  }, [_c('ws-msg', {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      "id": "push-msg-info"
    }
  })], 1)] : _vm._e(), _c('el-dropdown', {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "avatar-wrapper"
  }, [_c('img', {
    staticClass: "user-avatar",
    attrs: {
      "src": _vm.avatar
    }
  }), _c('i', {
    staticClass: "el-icon-caret-bottom"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('router-link', {
    attrs: {
      "to": "/user/profile"
    }
  }, [_c('el-dropdown-item', [_vm._v("个人中心")])], 1), _c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        _vm.setting = true;
      }
    }
  }, [_c('span', [_vm._v("布局设置")])]), _c('el-dropdown-item', {
    attrs: {
      "divided": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("退出登录")])])], 1)], 1)], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('td', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": "/static/images/weather.svg"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('td', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": "/static/images/humidity.png"
    }
  })]);
}];
export { render, staticRenderFns };