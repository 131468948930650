import variables from '@/assets/styles/element-variables.scss';
import defaultSettings from '@/settings';
const {
  sideTheme,
  showSettings,
  topNav,
  tagsView,
  fixedHeader,
  sidebarLogo
} = defaultSettings;
const storageSetting = JSON.parse(localStorage.getItem('layout-setting')) || '';
const state = {
  theme: storageSetting.theme || variables.theme,
  sideTheme: storageSetting.sideTheme || sideTheme,
  showSettings: showSettings,
  topNav: storageSetting.topNav === undefined ? topNav : storageSetting.topNav,
  tagsView: storageSetting.tagsView === undefined ? tagsView : storageSetting.tagsView,
  fixedHeader: storageSetting.fixedHeader === undefined ? fixedHeader : storageSetting.fixedHeader,
  sidebarLogo: storageSetting.sidebarLogo === undefined ? sidebarLogo : storageSetting.sidebarLogo
};
const mutations = {
  CHANGE_SETTING: (state, {
    key,
    value
  }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  }
};
const actions = {
  changeSetting({
    commit
  }, data) {
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};