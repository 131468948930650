import "core-js/modules/es.error.cause.js";
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from "@/store";
// 创建axios实例
const service = axios.create({
  baseURL: "/api",
  // api 的 base_url
  timeout: 10 * 1000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
  return config;
}, error => {
  // Do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(response => {
  if (response.status !== 200) {
    throw Error(response.status + response.statusText);
  }
  let res = response.data;
  // 成功返回
  if (res.state === true) {
    return Promise.resolve(res.data);
  }
  //错误返回
  let error = res.error;
  //系统异常
  if (error.code === "000000") {
    Message({
      message: error.msg,
      type: 'error',
      duration: 5 * 1000
    });
    // store.dispatch('LogOut').then(() => {
    //     location.href = '/index';
    // })
    throw Error(error.code + error.msg);
  }
  //无权限
  if (error.code === "000004") {
    Message({
      message: error.msg,
      type: 'error',
      duration: 5 * 1000
    });
    throw Error(error.code + error.msg);
  }
  //session失效
  if (error.code === "000002" || error.code === "000003") {
    MessageBox.alert('你已被登出，请重新登录！', '确定登出', {
      confirmButtonText: '确定',
      callback: action => {
        store.dispatch('LogOut').then(() => {
          location.href = '/index';
        });
      }
    });
    throw Error(error.code + error.msg);
  }
  Message({
    message: "[" + error.code + "]" + error.msg,
    type: 'info',
    duration: 5 * 1000
  });
  return Promise.reject(error);
}, error => {
  console.log('err' + error); // for debug
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;