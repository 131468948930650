import logoImg from '@/assets/logo/logo.png';
import variables from '@/assets/styles/variables.scss';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme;
    }
  },
  data() {
    return {
      title: '安城智慧消防',
      logo: logoImg
    };
  }
};