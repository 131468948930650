var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.dialog.isShow ? _c('div', [_c('el-dialog', {
    attrs: {
      "title": _vm.dialog.title,
      "visible": _vm.dialog.isShow,
      "width": _vm.dialog.width,
      "before-close": _vm.cancel,
      "close-on-click-modal": _vm.clickClose,
      "close-on-press-escape": _vm.pressClose,
      "append-to-body": _vm.appendToBody,
      "modal": _vm.modal
    },
    on: {
      "update:visible": function ($event) {
        return _vm.$set(_vm.dialog, "isShow", $event);
      }
    }
  }, [_vm._t("default"), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_vm.dialog.isAdd ? _c('el-checkbox', {
    staticStyle: {
      "margin-right": "20px"
    },
    model: {
      value: _vm.dialog.addContinue,
      callback: function ($$v) {
        _vm.$set(_vm.dialog, "addContinue", $$v);
      },
      expression: "dialog.addContinue"
    }
  }, [_vm._v("继续添加")]) : _vm._e(), !_vm.dialog.isSingle ? _c('el-button', {
    attrs: {
      "size": "mini"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取 消")]) : _vm._e(), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("确 定")])], 1)], 2)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };