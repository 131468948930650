import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import TopNav from '@/components/TopNav';
export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger
  },
  computed: {
    ...mapGetters(['sidebar']),
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      }
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    }
  }
};