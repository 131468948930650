var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "hello-ezuikit-js"
  }, [_c('div', {
    staticStyle: {
      "width": "400px",
      "height": "450px"
    },
    attrs: {
      "id": "video-container"
    }
  })]);
}];
export { render, staticRenderFns };