import { getToken, removeToken } from '@/utils/auth';
import { LoginApi } from '@/api/LoginApi';
const user = {
  state: {
    token: getToken(),
    name: '',
    userType: 1,
    avatar: '',
    roles: [],
    permissions: [],
    projects: [],
    provider: null
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_USERTYPE: (state, userType) => {
      state.userType = userType;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_PROJECTS: (state, projects) => {
      state.projects = projects;
    },
    SET_PROVIDER: (state, provider) => {
      state.provider = provider;
    }
  },
  actions: {
    // 退出系统
    LogOut({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        LoginApi.logout().then(() => {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_PERMISSIONS', []);
          commit('SET_PROJECTS', []);
          commit('SET_PROVIDER', null);
          removeToken();
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
export default user;