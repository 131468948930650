export default {
  data() {
    return {
      sizeOptions: [{
        label: '默认',
        value: 'small'
      }, {
        label: '中等',
        value: 'medium'
      }, {
        label: '小',
        value: 'small'
      }, {
        label: '很小',
        value: 'mini'
      }]
    };
  },
  computed: {
    size() {
      return this.$store.getters.size;
    }
  },
  methods: {
    handleSetSize(size) {
      this.$ELEMENT.size = size;
      this.$store.dispatch('app/setSize', size);
      this.refreshView();
      this.$message({
        message: '切换成功',
        type: 'success'
      });
    },
    refreshView() {
      // In order to make the cached page re-rendered
      this.$store.dispatch('tagsView/delAllCachedViews', this.$route);
      const {
        fullPath
      } = this.$route;
      this.$nextTick(() => {
        this.$router.replace({
          path: '/redirect' + fullPath
        });
      });
    }
  }
};